// 给多维数组添加层级标识
export function arrayTreeAddLevel(array, levelName = 'level', childrenName = 'childList') {
  if (!Array.isArray(array)) return []
  const recursive = (array, level = 0) => {
    level++
    return array.map(v => {
      v[levelName] = level
      const child = v[childrenName]
      if (child && child.length) recursive(child, level)
      return v
    })
  }
  return recursive(array)
}

function bfsLoop(data, fn) {
  let arr = [...data]
  let node
  //分批往数组末尾塞数据
  while ((node = arr.shift())) {
    fn(node)
    node.childList && arr.push(...node.childList)
  }
}

export function getDeepLeafs(list = [], key = [], keyName = 'id') {
  var targetArr = [],
    leafsArr = []
  bfsLoop(list, e => {
    e[keyName] == key && targetArr.push(e)
  })
  bfsLoop(targetArr, e => {
    !e.childList && leafsArr.push(e[keyName])
  })
  return leafsArr
}


export function phoneNuberConvert(number) {
  if (!number) return "";
  let pat = /(\d{3})\d*(\d{4})/;
  let result = number.replace(pat, "$1***$2");
  return result;
}
