import axios from "@/api/request";
import {prize} from "@/api/base";

// 开发不需要nginx转发，别的环境需要转发/prize的接口
const prizeBase = process.env.NODE_ENV=="development"?'':'/prize'
export const buryingPoint = (params)=>{
	// return {}
	return axios.get(`${prize}${prizeBase}/api/v1/action/${params.userId}/heartbeat`,{params})
}
export const getPoints = (params)=>{
	return axios.get(`${prize}${prizeBase}/api/v1/integral/userId/${params.userId}/get`,{params})
}

export const getBanner = (params)=>{
	return axios.get(`${prize}${prizeBase}/api/v1/banner`,{params})
}
export const getCurrent = (params)=>{
	return axios.get(`${prize}${prizeBase}/api/v1/activity/current`,{params})
}
// export const getActivity = (params)=>{
// 	return axios.get(`${prize}${prizeBase}/api/v1/activity`,{params})
// }
