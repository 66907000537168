<template>
  <div>
    <template v-if="dataList.length">
      <div class="book-list">
        <div v-for="item in dataList" :key="item.bookId" class="book" @click="handleItemClick(item)">
          <span class="tag" v-if="item.tryRead">试读</span>
          <div class="cover-container">
            <img :src="item.bookCoverUrl" :alt="item.bookCoverUrl" class="cover" />
          </div>
          <span class="name" :title="item.bookName">{{ item.bookName }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="empty">暂无内容</p>
    </template>
    <ActiveBook :visible.sync="activeBookVisible" @refresh="refresh" />
  </div>
</template>

<script>
import ActiveBook from '@/components/activeBook.vue'
import {getBookInfo, getYSTBookInfo} from '@/api/videoResource'
import {mapActions} from "vuex";

export default {
  props: {
    dataList: {
      required: true
    },
    isActived: {
      default: false
    }
  },
  name: 'BookList',
  components: {
    ActiveBook
  },
  data() {
    return {
      userId: '',
      bookName: '',
      bookId: '',
      activeBookVisible: false
    }
  },
  created() {
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      this.userId = JSON.parse(userInfo).userId
    }
  },
  methods: {
    ...mapActions(['toClassServer']),
    refresh() {},
    goto(item){
      const { href } = this.$router.resolve({
        path: '/videoLearning',
        query: {
          bookId: this.bookId,
          bookName: item.bookName,
          resourceType:item.resourceType
        }
      })
      window.open(href)
    },
    async handleItemClick(item) {
      if (!this.userId) {
        // 未登录，提示用户登录
        let info = this.$info({
          centered: true,
          okText: '我知道了',
          icon: h => h('a-icon', { props: { type: 'info-circle' }, attrs: { style: `color: #f6492b` } }, ''),
          content: h =>
            h('p', {}, [
              '您尚未登录，请先',
              h(
                'span',
                {
                  attrs: { style: `color: #f6492b` },
                  on: {
                    click: () => {
                      info.destroy()
                      this.$router.push('/loginRegistration?loginStep=1')
                    }
                  }
                },
                '登录'
              )
            ])
        })
        return
      }

      //记录当前选择的教材信息
      this.bookName = item.bookName
      this.bookId = item.bookId

      //激活教材
      if (!item.existsFlag && !item.tryRead && !this.isActived) {
        this.activeBookVisible = true
        return
      }
      //预览/下载教材
      switch (item.resourceType) {
        //h5预览
        case 3: {
          const { data } = await getBookInfo({ bookId: this.bookId })
          // window.open(data[0].url)
					const url = location.protocol + '//' + location.host + '/#/preview?bookId=' + this.bookId + '&url=' + encodeURIComponent(data[0].url ? data[0].url.replace(/https:|http:/,'') : '')
          window.open(url, '_blank')
          break
        }
        //新开下载zip包
        case 4: {
          this.$confirm({
            title: '该教材需要下载到本地学习，要下载吗？',
            okText: '下载',
            okType: 'danger',
            cancelText: '取消',
            centered: true,
            onOk: async () => {
              const { data } = await getBookInfo({ bookId: this.bookId })
              window.open(data[0].url)
            },
            onCancel() {}
          })
          break
        }
        //打开预览界面
        case 5: {
          this.goto(item)
        }
        case 8: {
          this.goto(item)
        }
        case 10: {
          const { data } = await getBookInfo({ bookId: this.bookId })
          window.open(data[0].url)
          break
        }
        case 11: {
          const {data} = await getBookInfo({ bookId: this.bookId })
          // window.open(data[0].url)
          this.toClassServer({keyType: 'URL', redirectUrl:data[0].url})
         }
        case 13: {
          const {data} = await getYSTBookInfo({ bookId: this.bookId,userId: this.userId ,type: 1})
          window.open(data.link)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
@import url('~@/assets/css/theme.less');
.book-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 508px;
  .book {
    width: 208px;
    height: 222px;
    margin-right: 32px;
    margin-top: 32px;
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    &:nth-child(5n) {
      margin-right: 0;
    }
    &:hover {
      background: #f5f5f5;
    }
    .tag {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 4px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      color: @primary-color;
      background: fade(@primary-color, 10);
      border-radius: 2px;
      z-index: 9;
    }
  }
  .cover-container {
    position: relative;
    width: 144px;
    height: 150px;
    margin: auto;
    margin-bottom: 12px;
    word-break: break-all;
    .cover {
      width: 100%;
      height: 100%;
      position: relative;
      margin: auto;
      object-fit: cover;
      object-position: center;
    }
  }
  .name {
    width: 144px;
    margin: auto;
    display: -webkit-box !important;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 24px;
  }
}

.empty {
  padding: 48px;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}

/deep/.ant-btn-primary {
  background-color: #ff4c39 !important;
  border: none;
}
</style>
