<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <HeaderModule v-if="!$route.meta.hide"/>
      <Contact-information v-if="!$route.meta.hide"></Contact-information>
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import cn from 'ant-design-vue/lib/locale-provider/zh_CN';
import HeaderModule from './components/Header';
import ContactInformation from './components/contactInformation.vue';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {getCurrent} from "@/api/prize";
moment.locale('zh-cn');
export default {
  components: {
    HeaderModule,
    ContactInformation
  },
  data() {
    return {
      zh_CN: cn,
      agent: '',
      isMac: ''
    };
  },
  created() {
    this.getCurrent();
    const isMobile = this.ismobile();
		// !this.$route.query.client_id 判断是否免登跳转过来的
    if (isMobile&&window.location.href.indexOf("client_id") ==-1) {
      if(~location.hash.indexOf("#/course3")){
        window.location.href = 'https://zjy.zjeav.com/#/mobileH5/course3';
      }else{
        window.location.href = 'https://zjy.zjeav.com/#/mobileH5';
      }
    }
  },
  methods: {
    ismobile() {
      let mobileArry = ['iPhone', 'iPad', 'Android', 'Windows Phone', 'BB10; Touch', 'BB10; Touch', 'PlayBook', 'Nokia'];
      let ua = navigator.userAgent;
      let res = mobileArry.filter(function (arr) {
        return ua.indexOf(arr) > 0;
      });
      return res.length > 0;
    },
   async getCurrent(){
    const res = await getCurrent()
     if(res.data?.activityCode)
    localStorage.setItem('activityCode', res.data.activityCode)
    }
  },
  mounted() {
    // this.$router.afterEach((to, from, next) => {
    //     window.scrollTo(0, 0)
    // })
  }
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
ol,
ul,
dl {
  margin-bottom: 0 !important;
}
html,
body {
  //font-family: "microsoft yahei" PingFangSC-Light -apple-system sans-serif !important;
}
#app {
  margin: 0 auto;
  height: 100vh;
  font-size: 16px;
  min-width: 1200px;
  overflow: auto;
  background: #f4f4f4;
}
</style>
