<template>
  <div class="about-us">
    <div class="about">
      <div class="copyRight">
        <h2>版权声明：</h2>
        <p>浙江电子音像出版社有限公司（以下简称“平台方”）拥有浙江省数字教材服务平台内相关内容（包括但不限于文字、图片、音频、视频资源及页面设计、编排、软件等）的版权及其他相关知识产权。本平台内的第三方资源经原创出版社授权使用，备有版权效力。</p>
        <p>除非中国法律另有规定，未经平台方或版权方书面许可，对于浙江省数字教材服务平台的所有内容，任何人不得复制或在非平台方所属的服务器上做镜像或以其他任何方式进行使用。违反上述声明给平台方造成损失的，浙江电子音像出版社有限公司将依法追究其法律责任。</p>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="line2">
        <a href="#" @click="goCopyRight">版权声明</a> |
        <a @click="toOther('http://www.beian.gov.cn/portal/registerSystemInfo')">公安备案号：33010302003168</a> | <a
        @click="toOther('https://beian.miit.gov.cn/#/Integrated/index')">工信部备案号：浙ICP备11047044号</a> |
        <a href="#"> 出版物经营许可证：浙批-300476号
        </a> | <a href="#">网络出版服务许可证：浙-003号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyRight',
  methods: {
    goCopyRight() {
      this.$router.push('/copyRight')
    },
    toOther(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.about-us {
  height: calc(~"100% - 80px");
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  .about {
    width: 1200px;
    margin: 16px auto;
    background-color: #fff;
    border-radius: 8px;
    flex: 1;
    > img {
      display: block;
      width: 100%;
    }

    .copyRight {
      width: 1200px;
      margin: 16px auto;
      background: #ffffff;
      border-radius: 8px;
      padding: 50px 50px 306px;

      h2 {
        margin-bottom: 20px;
        line-height: 24px;
        border-left: 6px solid #f6492a;
        padding-left: 12px;
      }
      
      p {
        font-size: 18px;
        color: #757475;
        text-indent: 2em;
        line-height: 36px;
        margin: 0;
      }
    }
  }
  .footer-bottom {
    flex-shrink: 0;
    display: inline-block;
    height: 62px;
    background: #333;
    text-align: center;
    .line2 {
      display: inline-block;
      width: 1200px;
      line-height: 62px;
      font-size: 14px;
      color: #999;
      a {
        color: #999;
      }
    }
  }
}
</style>
