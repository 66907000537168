<template>
   <div class="myBanner">
    <a-carousel v-show="data.length" :dots="false" easing="ease"  autoplay >
      <img @click="onClick(item)" :class="[item.type===1&&'clickable']" v-for="(item,index) in data" :key="index" :src="item.bannerCover">
    </a-carousel>
  </div>
</template>

<script>
import { getBanner} from '@/api/prize'
export default {
  data(){
    return {
      data:[]
    }
  },

  components:{},

  props:{
    position: Number
  },
  methods:{
    async getBanner(){
      this.data = [{
        bannerCover:'https://download.zjeav.com/eav-web/banner_web.png',
        type:1,
        linkUrl:'/prizeV1'
      }]
      this.data = (await getBanner({terminalType: 0,position: this.$props.position ?? '0'})).data
    },
    onClick(item){
      // if(item.type!==1){
      //   return
      // }
      if (item.linkUrl.startsWith('/')) {
          this.$router.push(item.linkUrl)
        } else {
          window.open(item.linkUrl,  '_blank')
        }
    }
  },

  mounted(){
    this.getBanner()
  },

}
</script>

<style lang='less' scoped>
.clickable {
  cursor: pointer;
}
.myBanner {
  width: 1200px;
  margin:  auto;
  box-shadow: 0 4px 16px 0 rgba(35,126,139,0.10);
}
img {
  border-radius: 8px;
  width: 100%;
  height: 90px;
  object-fit: cover;
}
</style>