<template>
  <div>
    <div class="header" :class="{ 'header--home': isHome }">
      <div class="header-container">
        <div class="header_title" :style="{ backgroundImage: `url(${isHome ? logo1 : logo2})` }"></div>
        <div class="header-nav">
          <template v-for="(item, index) in navList">
            <a-popover overlayClassName="header-popover" v-if="index === 1" :key="index">
              <template slot="content">
                <div class="header-down">
                  <div class="header-down-item" @click.stop="$router.push('/videoResource')">网络学习</div>
                  <div class="header-down-item" @click.stop="$router.push('/material')">客户端下载</div>
                </div>
              </template>
              <div @click="navClick(item, index)" class="header-nav-item" :class="{ 'header-nav-item--home': isHome, 'header-nav-item--select': currentPosition == index }">
                {{ item.name }}
              </div>
            </a-popover>
            <div v-else @click="navClick(item, index)" class="header-nav-item" :class="{ 'header-nav-item--home': isHome, 'header-nav-item--select': currentPosition == index }">
              {{ item.name }}
            </div>
          </template>
        </div>
        <!-- <ul class="header_nav">
          <li v-for="(item, index) in navList" :key="index"
            :class="{ 'header_nav_single--select': currentPosition == index }" class="header_nav_single"
            @click="navClick(item, index)" @mouseenter="navMouseenter(item)" @mouseleave="navMouseLeave(item)">{{
                item.name
            }}
            <div v-if="index == 1" class="dropList" @mouseenter='showDrop = true' @mouseleave="showDrop = false"
              v-show="showDrop">
              <div class="item" @click.stop="$router.push('/material')">客户端下载</div>
              <div class="item" @click.stop="$router.push('/detail')">学习资源包介绍</div>
            </div>
          </li>
        </ul> -->
        <div class="header_login" v-if="!comUser.userName">
          <span class="link" @click="showLogin(true)">登录</span>
          <span class="link" @click="showLogin(false)">注册</span>
        </div>
        <div v-else>
          <!-- <img src="../assets/icon/head.svg" /> -->
          <a-popover overlayClassName="header-popover">
            <template slot="content">
              <div class="header-down">
                <div class="header-down-item" @click.stop="$router.push('/userInfo')">用户中心</div>
                <div class="header-down-item" @click.stop="logout">退出登录</div>
              </div>
            </template>
            <span class="header-user" :class="{ 'header-user--home': isHome }">{{ user.userName }}</span>
          </a-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '../common/bus';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Cookies from 'js-cookie';
import logo1 from '@/assets/logo_light.png';
import logo2 from '@/assets/logo_dark.png';
import {getZjyConfig} from '@/api/user'
export default {
  name: 'Header',
  data() {
    return {
      showZjy:false,
      logo1,
      logo2,
      showDrop: false,
      showDropList: false,
      currentPosition: null,
      //定义列表数据

      showTool: false,
      user: {
        userName: ''
      }
    };
  },
  computed: {
    comUser() {
      return this.$store.state.userModule.user;
    },
    isHome() {
      return this.$route.meta.home;
    },
    navList(){
      const ret =   [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '网络学习/下载',
          path: '/videoResource'
        },
        {
          name: '资源专区',
          path: '/downloadSection'
        },
        // {
        //   name: '音像教材',
        //   path: '/videoResource'
        // },

        // {
        //   name: "数字教材",
        //   path: "/"
        // },
        // {
        //   name: "教师培训",
        //   path: ""
        // },
        // {
        //   name: "数字资源",
        //   path: "/resource"
        // },
        // {
        //   name: '抗疫助学',
        //   path: ''
        // }
      ]
      if(this.showZjy){
        ret.push({
          name: "课后服务课程",
          path: ()=>{
            if(this.comUser.userName){
              this.toClassServer({replace: false, redirectUrl: `https://330.zjeav.com/`})
            }else{
              window.open(`https://330.zjeav.com/`,'_blank')
            }
          }
        })
      }
      return ret
    }
  },
  watch: {
    // 监听 this.$route.path     watch监听非DOM元素的改变
    '$route.path': {
      immediate: true,
      handler: function (newVal, oldVal) {
        // console.log(newVal + "---" + oldVal);
        switch (newVal) {
          case '/':
            this.currentPosition = 0;
            break;

          case '/material':
            this.currentPosition = 1;
            break;
          case '/detail':
            this.currentPosition = 1;
            break;
          case '/downloadSection':
            this.currentPosition = 2;
            break;

          case '/videoResource':
            this.currentPosition = 1;
            break;
          case '/resource':
            this.currentPosition = 3;
            break;
          default:
            this.currentPosition = null;
        }
      }
    },
    comUser: {
      handler: function (val) {
        this.user = val;
      }
    }
  },
  methods: {
    ...mapActions(['toClassServer']),
    async getZjyConfig (){
      const {data} = await getZjyConfig()
      this.showZjy =  data.mustFlag
    },
    showLogin(isLogin) {
      this.$router.push({ path: '/loginRegistration', query: { loginStep: isLogin ? 1 : 0 } });
    },
    //点击当前dom，获取当前位置和索引是否相等，相等则不执行

    // navClick(item,index) {
    //     if(index==this.currentPosition){
    //         return false
    //     }

    //如果不等，将索引赋值给当前位置，改变--select的样式

    //   this.currentPosition = index;
    //   this.$router.push(item.path)
    // },

    //在新页面执行跳转

    // toOtherWebsite() {
    //   window.open('http://www.zjeav.com/','_blank')
    // }
    ...mapMutations(['setUser']),
    //粗略写法 后面来改
    navClick(item, index) {
      // if (index == 3) window.open("http://zsh.zjeav.com/xuehai_teacher_training/front/#/login", "_blank")
      // else if (index == 5) {
      //   window.open("https://book.zjeav.com/", "_blank")
      // } else if (index == 2) {
      //   this.currentPosition = 0
      // }
      // else {
      //   if (index == this.currentPosition) {
      //     return false
      //   }
      //   this.currentPosition = index
      //   this.$router.push(item.path)
      // }
      // if (index == 3) {
        // window.open('https://book.zjeav.com/', '_blank');
        // } else if (index == 2) {
        //   location.href = 'https://zjy.zjeav.com/#/videoResource'
      // } else {
        if(typeof item.path === 'function'){
          return item.path()
        }
        if(item.path.startsWith('http')){
          return window.open(item.path)
        }
        if (index == this.currentPosition) {
          return false;
        }
        this.currentPosition = index;
        this.$router.push(item.path);
      // }
    },
    //
    navMouseenter(item) {
      if (item.name === '网络学习/下载') {
        this.showDrop = true;
      } else if (item.name === '数字教材') {
        this.showDropList = true;
      }
    },
    navMouseLeave(item) {
      if (item.name === '网络学习/下载') {
        this.showDrop = false;
      } else if (item.name === '数字教材') {
        this.showDropList = false;
      }
    },
    goNewWebsite(item) {
      // console.log(item)
      if (item == 'digitalBook') {
        location.href = 'https://zjy.zjeav.com/#/digitalBook';
      } else {
        location.href = 'https://zjy.zjeav.com/#/videoResource';
      }
    },
    logout() {
      localStorage.removeItem('user');
      this.user = { userName: '' };
      this.setUser({});
      Cookies.remove('commonName', { domain: 'zjeav.com' });
      this.$router.push('/');
    }
  },
  mounted() {
    this.getZjyConfig()
    let vm = this;
    bus.$on('val', e => {
      vm.currentPosition = e;
    });
    if (localStorage.user) {
      this.user = JSON.parse(localStorage.user);
			this.setUser(this.user);
    } else if (Cookies.get('commonName', { domain: 'zjeav.com' })) {
      this.user = JSON.parse(Cookies.get('commonName', { domain: 'zjeav.com' }));
    }
    // document.querySelector('body').onclick = () => (this.showTool = false);
  }
};
</script>

<style lang="less">
.header {
  width: 100%;
  box-sizing: border-box;
  height: 72px;
  background: #fff;

  &-user {
    color: #666;
    &--home {
      color: #fff;
      font-size: 16px;
      position: relative;
      top: 4px;
      cursor: pointer;
    }
  }

  &-popover {
    margin-top: -20px !important;

    .ant-popover-content,
    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-inner {
      background-color: unset;
      box-shadow: none;
    }

    .ant-popover-arrow {
      display: none;
    }
  }

  &-down {
    padding: 10px 0;
    width: 198px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(61, 68, 102, 0.1);
    border-radius: 8px;

    &-item {
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 50px;
    }
  }

  &-container {
    width: 1200px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--home {
    position: absolute;
    top: 0;
    backdrop-filter: blur(16px);
    background: transparent;
  }

  z-index: 999;
  display: flex;
  justify-content: space-around;

  &_title {
    // background-image: url('~@/assets/logo_light.png');
    width: 360px;
    height: 40px;
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &-nav {
    display: flex;
    align-items: center;
    margin-top: 8px;
    flex: 1;
    justify-content: flex-end;
    &-item {
      cursor: pointer;
      font-size: 18px;
      font-weight: 400;
      margin-right: 48px;
      color: #666;

      &--home {
        color: #fff;
        &--select {
          background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.6));
        }
      }

      &--select {
        font-weight: 600;
        color: rgba(255, 255, 255, 0);
        background: linear-gradient(to right, #fb8056 0%, #f6492b 100%);
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  &_nav {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .dropList {
      position: absolute;
      left: 0;
      top: 80px;
      width: 216px;
      background: white;
      z-index: 1;

      .item {
        cursor: pointer;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
        color: #555555;

        &:hover {
          color: #f6492b;
        }
      }
    }

    .dropLists {
      position: absolute;
      left: 0;
      top: 80px;
      width: 104px;
      background: white;
      z-index: 1;

      .item {
        cursor: pointer;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
        color: #555555;
        &:hover {
          color: #f6492b;
        }
      }
    }

    &_single {
      list-style: none;
      padding: 0 20px;
      height: 100%;
      margin-left: 10px;
      font-size: 16px;
      color: #555555;
      line-height: 80px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.1s ease-in;
      position: relative;

      &--select {
        color: #f6492b;
        border-bottom: 3px solid #f6492b;
      }

      &:hover {
        color: #f6492b;
        border-bottom: 3px solid #f6492b;
      }
    }
  }

  &_login {
    background: linear-gradient(90deg, #fb8056 0%, #f6492b 100%);
    border-radius: 18px;
    padding: 0 8px;
    position: relative;
    margin-top: 8px;
    ::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 18px;
      background: #ffffff;
      opacity: 0.2;
    }

    .link {
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      line-height: 29px;
      padding: 0 8px;
    }

    // .tool {
    //   width: 84px;
    //   background: #ffffff;
    //   box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
    //   position: absolute;
    //   z-index: 5;
    //   font-size: 14px;
    //   top: 60px;
    //   left: 72px;

    //   .item {
    //     text-align: center;
    //     line-height: 38px;
    //     cursor: pointer;
    //   }

    //   .link {
    //     height: 1px;
    //     background: rgba(0, 0, 0, 0.06);
    //   }
    // }
  }
}

.header:after {
  content: '';
  clear: both;
}

// @media screen and (min-width: 1920px) {
//   .header_nav {
//     flex-basis: 1200px;
//   }
// }
.header-nav-item{
  font-size: 16px;
}
.header-nav-item:hover {
  color: #f6492b;
}

.header-down-item:hover {
  color: #f6492b;
}
</style>
