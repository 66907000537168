import JSEncrypt from 'jsencrypt'
import {message} from "ant-design-vue";
const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8aPxsHkWQjrdSOmbhThU
eAABQAgu/VT5nkEaYZovVJamRO9+sSnUbsHhyOZNXeLuhBlbu4JXMF9LGbvUVTeI
nBbrT7rD4EHup2aA/C8836bS//VvghqdzUVppbxHfJ+uOejXITo3gtayTo48F0gh
GF0bocnkq9AVUnuCgdWJ5IMfAABlVfpqDRkm3Sht0IUDxmwOONvnw28NJZvQuo4k
3mCqYZLoxRHmBpBFqMeBVL/52wdq1xd94n/SUCB4EMAsYq5RblWRZlOct7dlssb2
kpNvweFag6Sf6lIvYxgqJZfLr6lwfQ3H4/M7YVeAH2ZHxKf5F64xjVUBx1spe8wL
ZwIDAQAB`
const publicKeyUrl = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgYa6+iAlzsnN6PJDVAdj
KzM7Uo1nM3HbdEylK5suqrq9MVE+NOKqp88SFmRfbt7lxyPfoTfbxUT2+JDhKHkf
euln0V6+4BTbqWwKpo+0lHOhg2VlKjZ5EZab5+vQw34uSZ7Bf7Pt8eMABn4Q+cd9
/VOTm10wCRFvL6JT3cm4qnHfsssTrAA0vOgG3qUn2XXEzLpxvsdP7se5MvAMdHxz
NiRZwaOTQ1a8O5VMrHYBl/vttws+TUEVAhW+ogi2uHH0ixKWKHxThlHSmxLB6Rg8
CWyfndkHo62CY00aKiWnOeokmQAwrWDcn4gsS10mQhCQi6ON7QcC5KF7W1k8h6yo
lQIDAQAB
`
export default {
    state: {
        user: {},
        zjerParam: {},
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getZjerParam(state) {
            return state.zjerParam
        },
        getIsTeacher(state) {
            return state.user.roles?.includes('teacher')
        },
        getIsZjerTeacher(state) {
            return state.user.roles?.includes('teacher') && (!!state.user.personId || state.user.zsVerified)
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setZjerParam(state, zjerParam) {
            state.zjerParam = zjerParam
        }
    },
    actions: {
        toClassServer({commit, state}, option = {}) {
            const user = state.user.userId ? state.user : (JSON.parse(localStorage.user || {}))
            if (!user.phone) {
                return void message.warning('请绑定手机号')
            }
            const encryptor = new JSEncrypt()
            if (option.keyType === 'URL') {
                encryptor.setPublicKey(publicKeyUrl) // 设置公钥
            } else {
                encryptor.setPublicKey(publicKey) // 设置公钥
            }
            const url = option.redirectUrl, params = `${option.redirectUrl.indexOf('?')<0?'?':'&'}key=${btoa(encodeURI(encryptor.encrypt(JSON.stringify({
                userId: user.userId,
                userName: user.userName || '',
                phone: user.phone || ''
            }))))}`
             if (option.replace) {
                location.href = url + params
            } else {
                window.open((url.indexOf('http') < 0 ? 'https://' : '') + url + params, '_blank')
            }
            sessionStorage.removeItem('redirectUrl')
        }
    }
}
